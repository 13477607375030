import { View } from '@neos/app'

export const views: View[] = [
  {
    name: 'TmsAscOrderTrackingOperatingEventUI',
    openMode: 'newFrame',
    component: () => import('./TmsAscOrderTrackingOperatingEventView/TmsAscOrderTrackingOperatingEventUI/view/TmsAscOrderTrackingOperatingEventUI.vue')
  },
  {
    name: 'TmsAscOrderTrackingUI',
    openMode: 'newFrame',
    component: () => import('./TmsAscOrderTrackingView/TmsAscOrderTrackingUI/view/TmsAscOrderTrackingUI.vue')
  },
]