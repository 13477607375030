import type { Images } from '@neos/app'
import tmsAscCheckPath from '../../modules/TmsAscTracking/images/TmsAscCheck.svg'
import tmsAscDeliveryPath from '../../modules/TmsAscTracking/images/TmsAscDelivery.svg'
import tmsAscInTransitOrderStatusPath from '../../modules/TmsAscTracking/images/TmsAscInTransitOrderStatus.svg'

const images: Images = {
  tmsAscCheck: { path: tmsAscCheckPath, colored: false },
  tmsAscDelivery: { path: tmsAscDeliveryPath, colored: false },
  tmsAscInTransitOrderStatus: { path: tmsAscInTransitOrderStatusPath, colored: false },
}

export default images