import { createApp } from '@neos/app'
import { views } from './views'
import { menuItems } from './menuItems'
import images from './images'
import '@neos/app/dist/style.css'
import '@neos/ai/dist/style.css'

const resources = import.meta.glob<boolean, string, { default: Record<string, unknown> }>(`./Resources/*.ts`)

const themes = import.meta.glob<boolean, string, string | undefined>('./Themes/*.css', {
  query: '?inline',
  import: 'default',
})

createApp({
  production: import.meta.env.PROD,
  name: `TMSAscTracking`,
  title: `TMSAscTracking`,
  version: `1.0.0-alpha.49`,
  company: `Groupe Isagri Services`,
  languages: [`en`, `fr`],
  importResources: (lang) => resources[`./Resources/${lang}.ts`],
  themes: [`Neos`, `NeosV1`, `TmsAscTracking`],
  importTheme: (theme) => themes[`./Themes/${theme}.css`]?.(),
  hotModuleReload: import.meta.hot,
  views,
  menuItems,
  images,
  hasUICustomizationModule: false,
  mainUIViewName: `TmsAscOrderTrackingUI`,
  uiDefaultBehavior: {},
})